import React, { useState } from 'react';
import { createRoom } from './comms';

import './Entry.css';

type EntryProps = {
  entryError: string | null;
}

function Entry({ entryError }: EntryProps): React.ReactElement | null{
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  return <div className="entry">
    <h1>Dephrase</h1>

    {entryError ? <div className="error">{entryError}</div> : null}

    <button onClick={async (e) => {
      setButtonDisabled(true);

      try {
        await createRoom();
      } catch (e) {
        console.error(e);
        alert('Failed to join room');
        setButtonDisabled(false);
      }
    }} disabled={buttonDisabled}>Create Room</button>
  </div>
}

export default Entry;