import * as Colyseus from 'colyseus.js';
import { useState, useEffect } from 'react';

type GameStage = 'lobby' | 'game' | 'results';
type RoundHalf = 'describing' | 'guessing';
type ResultsViewState = {
  chainIndex: number;
  chainSlide: number;
}

export type FullRoundChainLink = {
  promptWord: string;
  bannedLemmas: string[];
  playerDescription: string;
  rewritePrompt: {
    fullPrompt: string;
    shortDescription: string;
  };
  rewrittenDescription: string;
};

export type Chain = {
  fullRoundChain: FullRoundChainLink[];
  playerIds: string[];
};

export const client = new Colyseus.Client('wss://dephrase-server.tja.io');

let roomMutex = false; // yoof idk
let room: Colyseus.Room | null = null;
const roomHandlers: { [key: string]: (room: Colyseus.Room | null) => void } = {};

const handleRoomChange = (room: Colyseus.Room | null) => {
  for (const handler of Object.values(roomHandlers)) {
    handler(room);
  }
};

const handleJoinedRoom = (room: Colyseus.Room) => {
  handleRoomChange(room);
  localStorage.setItem('dephrase_session_id', JSON.stringify({ roomId: room.id, sessionId: room.sessionId, lsVersion: 1 }));

  return room;
};

export const createRoom = async () => {
  if (roomMutex || room) {
    return null;
  }
  roomMutex = true;
  try {
    room = await client.create('dephrase_room');
    handleJoinedRoom(room);
  } finally {
    roomMutex = false;
  }
};

export const joinRoom = async (roomId: string) => {
  if (roomMutex || room) {
    return null;
  }
  roomMutex = true;
  try {
    room = await client.joinById(roomId);
    handleJoinedRoom(room);
  } finally {
    roomMutex = false;
  }
};

export const leaveRoom = async () => {
  await room?.leave(true);
  localStorage.removeItem('dephrase_session_id');
  room = null;
  handleRoomChange(room);
};

export const reconnect = async (roomId: string, sessionId: string) => {
  if (roomMutex || room) {
    return false;
  }
  roomMutex = true;
  // console.log('a');
  room = await client.reconnect(roomId, sessionId).finally(() => roomMutex = false);
  // console.log('b');
  handleJoinedRoom(room);
  return true;
};

export const useRoom = () => {
  const [thisRoom, setRoom] = useState<Colyseus.Room | null>(room);
  useEffect(() => {
    const handler = (r: Colyseus.Room | null) => {
      setRoom(r); // little silly with the global here but shh
    };
    const randomName = Math.random().toString(36);
    roomHandlers[randomName] = handler;
    return () => {
      delete roomHandlers[randomName];
    };
  });
  return thisRoom;
};

let roomState: any = null; // TODO
const roomStateHandlers: { [key: string]: (state: any) => void } = {};

roomHandlers['stateHandler'] = (room: Colyseus.Room | null) => {
  if (!room) {
    roomState = null;
    return;
  }

  room.onStateChange((state: any) => {
    roomState = state.toJSON();
    for (const handler of Object.values(roomStateHandlers)) {
      handler(roomState);
    }
  });
};

export const useRoomState = <T>(stateKey: string): T | null => {
  const [stateValue, setStateValue] = useState<T | null>(null);
  useEffect(() => {
    if (roomState) {
      setStateValue(roomState[stateKey]);
    }
    
    const handler = (state: any) => {
      setStateValue(state[stateKey]);
    }
    const randomName = Math.random().toString(36);
    roomStateHandlers[randomName] = handler;
    return () => {
      delete roomStateHandlers[randomName];
    };
  });
  return stateValue;
}

export const useGameStage = (): GameStage | null => {
  return useRoomState<GameStage>('gameStage');
};

export const useCurrentRound = (): number | null => {
  return useRoomState<number>('currentRound');
};

export const useCurrentRoundHalf = (): RoundHalf => {
  return useRoomState<RoundHalf>('currentRoundHalf')!;
};

export const useChains = (): Chain[] | null => {
  return useRoomState<Chain[]>('chains');
};

export const usePlayerNames = (): {[id: string]: string} | null => {
  return useRoomState<{[id: string]: string}>('playerNames');
};

export const useRoomSettings = (): { secondsPerRound: number } | null => {
  return useRoomState<{ secondsPerRound: number }>('roomSettings');
};

export const useCurrentChain = (): Chain | null => {
  const room = useRoom();
  const chains = useChains();
  const currentRound = useCurrentRound();
  const currentRoundHalf = useCurrentRoundHalf();

  if (!room || currentRound === null || !chains) {
    return null;
  }

  const myId = room.sessionId;
  const halfRound = currentRound * 2 + (currentRoundHalf === 'describing' ? 0 : 1);
  const [ chain ] = chains.filter(c => c.playerIds[halfRound] === myId);

  return chain;
};

export const useCurrentChainLink = (): FullRoundChainLink | null => {
  const currentChain = useCurrentChain();
  const currentRound = useCurrentRound();

  if (!currentChain || currentRound === null) {
    return null;
  }

  return currentChain.fullRoundChain[currentRound];
};

export const useCurrentRoundHalfExpiryTime = (): number | null => {
  return useRoomState<number>('currentRoundHalfExpiryTime');
};

export const sendDescription = (description: string) => {
  if (!room) {
    throw new Error('No room to send description to');
  }
  room.send('description', { description });
};

export const sendGuess = (guess: string) => {
  if (!room) {
    throw new Error('No room to send guess to');
  }
  room.send('guess', { guess });
};

export const sendChainIndex = (chainIndex: number) => {
  if (!room) {
    throw new Error('No room to send chain index to');
  }
  room.send('chainIndex', { chainIndex });
};

export const sendChainSlide = (chainSlide: number) => {
  if (!room) {
    throw new Error('No room to send chain slide to');
  }
  room.send('chainSlide', { chainSlide });
};

export const setPlayerName = (name: string) => {
  room?.send('setPlayerName', { name });
};

export const updateRoomSetting = (setting: string, value: any) => {
  room?.send('updateRoomSetting', { setting, value });
};

export const useResultsView = (): ResultsViewState | null => {
  return useRoomState<ResultsViewState>('resultsViewState');
};