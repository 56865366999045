import React, { useState } from 'react';
import { sendGuess, useCurrentChainLink } from './comms';

import './Guessing.css';

function Guessing(): React.ReactElement | null {
  const currentChainLink = useCurrentChainLink();
  const [guess, setGuess] = useState('');
  const [sentGuess, setSentGuess] = useState(false);

  if (currentChainLink === null) { // hope not!
    return null;
  }

  if (currentChainLink === null || !currentChainLink.rewrittenDescription) {
    // the server now sends all of these at once so it should be safe to just check this one
    return <div className="guess-loading">
      <div className="guess-loading-explanation">The AI is rewriting descriptions...</div>
      <div className="guess-loading-rewrite-prompt">{currentChainLink.rewritePrompt?.shortDescription?.toUpperCase()}</div>
    </div>;
  }

  return <div className="guess">
    <div className="guess-explanation">
      The AI has rewritten the last player's description. Guess what's being described!
    </div>
    <div className="guess-rewrite-prompt">
      {currentChainLink.rewritePrompt.shortDescription.toUpperCase()}
    </div>
    <div className="guess-rewritten-description">
      {currentChainLink.rewrittenDescription}
    </div>
    {
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendGuess(guess);
          setSentGuess(true);
        }}
      >
        <input
          className="guess-input"
          type="text"
          value={guess}
          onChange={(e) => setGuess(e.target.value)}
          disabled={sentGuess}
        />
        <input
          className="guess-submit"
          type="submit"
          value="Guess"
          disabled={guess.trim() === '' || sentGuess} />
      </form>
    }
    {
      sentGuess && <div className="guess-waiting">
        Waiting for other players to guess...
      </div>
    }
  </div>;
}

export default Guessing;