import React from 'react';
import { useGameStage } from './comms';
import Lobby from './Lobby';
import Game from './Game';
import ResultsView from './ResultsView';

function GameRoom(): React.ReactElement | null {
  const gameStage = useGameStage();

  if (gameStage === 'lobby') {
    return <Lobby />;
  } else if (gameStage === 'game') {
    return <Game />;
  } else if (gameStage === 'results') {
    return <ResultsView />;
  }

  return null;
}

export default GameRoom;