import React, { useEffect } from 'react';

import { joinRoom } from './comms';

import './UrlLoading.css'

type UrlLoadingProps = {
  roomId: string;
  onFail: () => void;
  onSuccess: () => void;
};

function UrlLoading(props: UrlLoadingProps): React.ReactElement {
  useEffect(() => {
    joinRoom(props.roomId).catch((e) => {
      console.error(e);
      props.onFail();
    }).then(result => {
      if (result !== null) { // in case it's called twice
        props.onSuccess();
      }
    });
  }, []);

  return <div className="url-loading">
    Loading into room...
  </div>;
}

export default UrlLoading;