import React, { useEffect } from 'react';
import { reconnect } from './comms';

type LocalStorageLoadingProps = {
  roomId: string;
  sessionId: string;
  onFail: () => void;
  onSucceed: () => void;
};

function LocalStorageLoading(props: LocalStorageLoadingProps): React.ReactElement | null {
  useEffect(() => {
    (async () => {
      try {
        const reconnectTrying = await reconnect(props.roomId, props.sessionId);
        if (!reconnectTrying) { // in case this isn't the first call
          return;
        }
        props.onSucceed();
      } catch (e) {
        console.error(e);
        props.onFail();
      }
    })();
  }, []);

  // return <div>Loading into room...</div>;
  return null;
}

export default LocalStorageLoading;