import React, { useEffect, useState } from 'react';
import { sendDescription, useCurrentChainLink, useCurrentRoundHalfExpiryTime } from './comms';
// @ts-ignore
import Lemmatizer from 'javascript-lemmatizer';

import './Describing.css';

const lemmatizer = new Lemmatizer();

const OVERRIDES = ['has']; // lemmatizer turns this into 'ha' which is a mess

// should probably do this on the server too
const sanitizeWrtLemmas = (text: string, bannedLemmas: string[], sanitizeLast: boolean = true) => {
  const split = text.split(' ');
  const allowedWords: string[] = [];
  for (const w of (sanitizeLast ? split : split.slice(0, -1))) {
    const sanitized = w.toLowerCase().replace(/[^a-z ]/g, '');
    if (OVERRIDES.includes(sanitized) || !lemmatizer.only_lemmas(sanitized).some((l: string) => bannedLemmas.includes(l))) {
      allowedWords.push(w);
    }
  }
  if (!sanitizeLast) allowedWords.push(split[split.length - 1]);

  return allowedWords.join(' ');
}

let lastSend: any = null;

function Describing(): React.ReactElement | null {
  const currentChainLink = useCurrentChainLink();
  const currentRoundHalfExpiryTime = useCurrentRoundHalfExpiryTime();
  const [lastClock, setLastClock] = useState(+new Date());
  const [currentWriting, setCurrentWriting] = useState('');
  const [hasSentDescription, setHasSentDescription] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setLastClock(+new Date());
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);
  
  useEffect(() => {
    if (!currentChainLink) return;

    const isOver = currentRoundHalfExpiryTime && lastClock > currentRoundHalfExpiryTime;

    if (isOver &&!hasSentDescription) {
      setHasSentDescription(true);

      sendDescription(sanitizeWrtLemmas(currentWriting, currentChainLink!.bannedLemmas, true));
    }
  }, [lastClock, currentChainLink, currentWriting, hasSentDescription, currentRoundHalfExpiryTime])
 /* 
  useEffect(() => {
    const interval = setInterval(() => {
      sendDescription(sanitizeWrtLemmas(currentWriting, currentChainLink!.bannedLemmas, true));
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [currentWriting, currentChainLink]); // TODO eww eww this is not actually working, it's going to send it every change ugh
  */
  if (!currentChainLink) {
    return <div className="describe-loading">
      Waiting for the next round to start...
    </div>
  }
  
  const isOver = currentRoundHalfExpiryTime && lastClock > currentRoundHalfExpiryTime;

  // server gives a little grace period in case clocks are out of sync
  // ok well i mean i think it'll just wait for everyone
  if (isOver) {
    return <div className="describe-over">
      Time's up!
    </div>
  }

  return <div className="describe">
    <div className="describe-prompt">
      Describe this in as much detail as you can.
    </div>
    <div className="describe-banned-words">
      Some relevant words are not allowed and will be automatically removed.
    </div>
    <div className="describe-prompt-word">
      {currentChainLink.promptWord}
    </div>
    <div className="describe-time-left">{Math.ceil(((currentRoundHalfExpiryTime || +new Date()) - lastClock) / 1000)}</div>
    <textarea value={currentWriting} onChange={e => {
      const newVal = e.target.value;
      setCurrentWriting(sanitizeWrtLemmas(newVal, currentChainLink.bannedLemmas, false));
      if (!lastSend || +new Date() - lastSend > 1000) {
        sendDescription(sanitizeWrtLemmas(newVal, currentChainLink!.bannedLemmas, true));
        console.log('sending', newVal);
        lastSend = +new Date();
      }
    }} />
  </div>;
}

export default Describing;
