import React from 'react';
import { useCurrentRoundHalf } from './comms';
import Describing from './Describing';
import Guessing from './Guessing';

function Game(): React.ReactElement {
  const roundHalf = useCurrentRoundHalf();

  if (roundHalf === 'describing') {
    return <Describing />;
  }

  return <Guessing />;
}

export default Game;