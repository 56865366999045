import React from 'react';
import { leaveRoom, setPlayerName, updateRoomSetting, usePlayerNames, useRoom, useRoomSettings } from './comms';
import QRCode from 'react-qr-code';

import './Lobby.css';

function Lobby(): React.ReactElement {
  const room = useRoom()!; // TODO hmm wonder if there are better ways to propagate the non-null info. we need dependent types lol
  const playerNames = usePlayerNames();
  const roomSettings = useRoomSettings();
  const [currentName, setCurrentName] = React.useState<string>('');
    
  const roomDirectLink = `${window.location.origin}/?roomId=${room.id}`;

  return <div className="lobby">
    <div className="lobby-qr-code-description">
      Have players scan this QR code to join the room.
    </div>
    <div className="lobby-qr-code">
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        viewBox={`0 0 256 256`}
        level="H"
        value={roomDirectLink} />
    </div>
    <div className="lobby-room-link">
      <a href={roomDirectLink}>Room link</a>
    </div>
    <div className="lobby-player-list">
      <h2>Players:</h2>
      <ul>
        {playerNames && Object.entries(playerNames).map(([id, player]) => {
          return <li key={id}>{player}</li>;
        })}
      </ul>
    </div>
    <div className="lobby-player-name-entry">
      <form onSubmit={(e) => {
        e.preventDefault();
        setPlayerName(currentName);
      }}>
        {/* todo: this isn't perfect, a refresh is awkward, but such is life */}
        <input
          type="text"
          placeholder="Enter your name"
          value={currentName}
          onChange={(e) => {
            setCurrentName(e.target.value);
          }} />
        <input type="submit" value="Save" />
      </form>
    </div>
    <div className="lobby-room-settings">
      <h2>Room Settings:</h2>
      {
        roomSettings &&
        // {/* secondsPerRound */}
        <div className="lobby-room-setting">
          <div className="lobby-room-setting-label">Seconds per round:</div>
          <div className="lobby-room-setting-value">{roomSettings.secondsPerRound}</div>
          <button className="lobby-room-setting-button"
            onClick={() => {
              updateRoomSetting('secondsPerRound', roomSettings.secondsPerRound - 5);
            }}>-5</button>
          <button className="lobby-room-setting-button"
            onClick={() => {
              updateRoomSetting('secondsPerRound', roomSettings.secondsPerRound + 5);
            }}>+5</button>
        </div>
      }
    </div>
    <button
      className="start-game-button"
      onClick={() => {
        if (window.confirm("Are you sure you're ready to start the game? Is everyone in?")) {
          room.send('startGame');
        }
      }}>
      Start Game
    </button>
    <button
      className="leave-room-button"
      onClick={() => {
        leaveRoom();
      }}>
      Leave Room
    </button>
  </div>;
}

export default Lobby;