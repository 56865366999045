import React, { useState } from 'react';
import './App.css';
import Entry from './Entry';
import GameRoom from './GameRoom';
import { leaveRoom, useRoom } from './comms';
import UrlLoading from './UrlLoading';
import LocalStorageLoading from './LocalStorageLoading';

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

type LocalStorageAttemptState = 'none' | 'attempting' | 'failed' | 'succeeded';

function App() {
  const room = useRoom();
  const [usingUrl, setUsingUrl] = useState<boolean>(!!params.roomId);
  const [tryingLocalStorage, setTryingLocalStorage] = useState<LocalStorageAttemptState>('none');
  const [showLeaveRoom, setShowLeaveRoom] = useState<boolean>(false);
  const [entryError, setEntryError] = useState<string | null>(null);

  if (room) {
    return <>
      <h1 className="app-header" onClick={() => setShowLeaveRoom(!showLeaveRoom)}>Dephrase</h1>
      <div className="leave-room-button-container" style={{ display: showLeaveRoom ? 'flex' : 'none' }}>
        <button
          className="leave-room-button"
          onClick={() => {
            if (window.confirm('Are you sure you want to leave the room?')) {
              leaveRoom();
            }
          }}>
          Leave Room
        </button>
      </div>
      <GameRoom />
    </>;
  }

  if (tryingLocalStorage === 'none') {
    const roomSessionId = localStorage.getItem('dephrase_session_id');
    if (!roomSessionId) {
      setTryingLocalStorage('failed');
      return null;
    } else {
      setTryingLocalStorage('attempting');
    }
  }

  if (tryingLocalStorage === 'attempting') {
    const roomSessionId = localStorage.getItem('dephrase_session_id');

    const { roomId, sessionId } = JSON.parse(roomSessionId!); // ugh
    if (usingUrl && params.roomId !== roomId) {
      setTryingLocalStorage('failed');
      return null;
    }
    return <LocalStorageLoading roomId={roomId} sessionId={sessionId} onFail={() => {
      setTryingLocalStorage('failed');
    }} onSucceed={() => {
      setTryingLocalStorage('succeeded');
    }} />;
  }

  if (usingUrl) {
    return <UrlLoading roomId={params.roomId} onFail={() => {
      setUsingUrl(false);
      setEntryError('Failed to join room');
    }}
    onSuccess={() => {
      setUsingUrl(false);
      setTryingLocalStorage('none');
    }} />;
  }

  if (tryingLocalStorage === 'succeeded') {
    // won't happen because we'll be in the room.
    // if it does, it's because we left the room. set tryingLocalStorage to failed so we don't try again.
    // this is what i get for using react state instead of a proper state machine
    setTryingLocalStorage('failed');
    return null;
  }

  return <>
    <Entry entryError={entryError} />
  </>;
}

export default App;
