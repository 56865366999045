import React from 'react';
import { sendChainIndex, sendChainSlide, useChains, usePlayerNames, useResultsView } from './comms';

import './ResultsView.css';

function ResultsView(): React.ReactElement | null {
  const chains = useChains();
  const resultsViewState = useResultsView();
  const playerNames = usePlayerNames()!;
  if (resultsViewState === null || chains === null) {
    return <div>Waiting for results...</div>;
  }

  const { chainIndex, chainSlide } = resultsViewState;

  if (chainIndex === -1) {
    return <div className="all-chains-view">
      {
        chains.map((chain, i) => {
          return <div className="chain-button" key={i} role="button" onClick={() => {
            sendChainIndex(i);
          }}>
            <h2>{chain.fullRoundChain[0].promptWord}</h2>
          </div>
        })
      }
    </div>
  }

  // this is very imperative! but it makes sense to me
  let chonksRemaining = chainSlide + 2; // slide 0 shows both the start prompt and the first player description

  const chonks = [];

  let linkIndex = 0;
  let nextPlayerNameIndex = 0;
  let slideType = 'word';
  let noMore = false;
  while (chonksRemaining > 0) {
    if (slideType === 'word') {
      chonks.push(
        <div key={chonks.length}>
          {linkIndex > 0 && 
            <div className="results-prompt-word-author">  
                {`${playerNames[chains[chainIndex].playerIds[nextPlayerNameIndex++]]} guessed:`}
            </div>
          }
          <div className="results-prompt-word">{chains[chainIndex].fullRoundChain[linkIndex].promptWord}</div>
        </div>
      );
      slideType = 'player description';
      if (linkIndex === chains[chainIndex].fullRoundChain.length - 1) {
        noMore = true;
        break;
      }
    } else if (slideType === 'player description') {
      chonks.push(
        <div key={chonks.length}>
          <div className="results-player-description-author">
            {`${playerNames[chains[chainIndex].playerIds[nextPlayerNameIndex++]]} wrote:`}
          </div>
          <div className="results-player-description">{chains[chainIndex].fullRoundChain[linkIndex].playerDescription}</div>
        </div>
      );
      slideType = 'rewrite';
    } else if (slideType === 'rewrite') {
      chonks.push(
        <div key={chonks.length}>
          <div className="results-rewrite-prompt">{chains[chainIndex].fullRoundChain[linkIndex].rewritePrompt.shortDescription.toUpperCase()}</div>
          <div className="results-rewritten-description">{chains[chainIndex].fullRoundChain[linkIndex].rewrittenDescription}</div>
        </div>
      );
      slideType = 'word';
      linkIndex++;
    }

    chonksRemaining--;
  }

  return <div className="individual-chain-view">
    {chonks}
    <div className="chain-slide-buttons">
      {chainSlide === 0 ?
        <button
          className="chain-left-button"
          onClick={() => {
            sendChainIndex(-1);
          }}>
          All words
        </button>
        :
        <button
          className="chain-left-button"
          onClick={() => {
            sendChainSlide(chainSlide - 1);
          }}>Back</button>

      }
      {noMore ?
        <button
          className="chain-right-button"
          onClick={() => {
            sendChainIndex(-1);
          }}>All words</button>
        :
        <button
          className="chain-right-button"
          onClick={() => {
            sendChainSlide(chainSlide + 1);
          }}>Next</button>

      }
    </div>
  </div>;
}

export default ResultsView;